import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout/layout'
import Seo from '../components/seo'
import PageTitle from '../components/page-title/page-title'
import Button from '../components/button/button'
import * as styles from '../styles/landing.module.css'

const ProductsPage = () => {
  return (
    <Layout>
      <PageTitle
        superTitle='PRODUCTS'
        title={<>Experience <span>the first chainless wallet</span> on Kadena</>}
        subTitle={<>Securely store crypto assets and interact with decentralized protocols without knowing how a blockchain works,<strong> all within one app.</strong></>}
      />
      <div className='text-center'>
        <Button text='Learn more about Linx' url='https://linxwallet.xyz' target='_blank' className='mt-5' />
      </div>
      <div className={styles.imagesContainer + ' d-flex mx-auto'}>
        <StaticImage alt="Linx logo" src="../images/Phone 1@2x.png" className={styles.leftPhoneWrapper} loading='eager' placeholder='blurred' />
        <StaticImage alt="Linx logo" src="../images/Phone 2@2x.png" className={styles.rightPhoneWrapper} loading='eager' placeholder='blurred' />
      </div>
    </Layout>
  )
}


export const Head = ({ location }) => <Seo title="Projects" locationPath={location.pathname} />


export default ProductsPage